import { funnelInteractionErrorType } from '../constants';
import { emitPanoramaCustomEvent } from './custom-event';

/**
 * Function to check if passed in document is a valid document to run query selector
 * @param { Document } currentDocument
 * @returns { boolean }
 */
export const isValidDocument = (currentDocument: Document): boolean => {
    try {
        if (
            currentDocument &&
            typeof currentDocument === 'object' &&
            typeof currentDocument.querySelector === 'function'
        ) {
            return true;
        }
    } catch (err) {
        // log to Panorama as an internal metric
        emitPanoramaCustomEvent({
            eventSource: 'panoramaInternal',
            eventType: funnelInteractionErrorType,
            eventContext: 'isValidDocument',
            eventDetail: err ? err.toString() : '',
        });
    }
    return false;
};

/**
 * Extract text/label from given DOM selector
 * @param {string | null | undefined} selector
 * @param { Document } currentDocument
 * @returns { string }
 */
export function extractText(
    selector: string | null | undefined,
    currentDocument: Document = document
) {
    if (!selector) {
        return '';
    }

    const documentToUse = isValidDocument(currentDocument) ? currentDocument : document;

    try {
        const element = documentToUse.querySelector(selector);
        return _extractTextContent(element).trim();
    } catch (err: any) {
        // log to Panorama as an internal metric
        emitPanoramaCustomEvent({
            eventSource: 'panoramaInternal',
            eventType: funnelInteractionErrorType,
            eventContext: 'extractText',
            eventDetail: err ? err.toString() : '',
        });

        return '';
    }
}

type CustomNode = Node & {
    tagName?: string;
};

// It is expected that we cannot trim the trailing beginning and ending whitespaces as this would require more
// complex logic to handle whitespaces between text nodes and html nodes. These trailing whitespaces are instead trimmed as part of `extractText`
function _extractTextContent(node: Node | null): string {
    if (!node) {
        return '';
    }

    let text: string = '';
    const customNode = node as CustomNode;
    if (node.nodeType === Node.TEXT_NODE) {
        // Trim duplicate whitespaces
        // Replace new lines
        text += (node.textContent || '').replace(/\s+/g, ' ').replace(/\n/g, ' ');
    } else if (customNode.tagName !== 'A') {
        const childNodes = node.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
            text += _extractTextContent(childNodes[i]);
        }
    }
    return text;
}

/**
 * Extract text/label from given DOM selector
 * @param {string | null | undefined} selector
 * @param { Document } currentDocument
 * @returns { string }
 */
export const extractTextContent = (
    selector: string | null | undefined,
    currentDocument: Document = document
): string => {
    let documentToUse = currentDocument;
    if (!isValidDocument(currentDocument)) {
        documentToUse = document;
    }
    if (selector) {
        try {
            const nameIdentifier = documentToUse.querySelector(selector);
            if (nameIdentifier?.textContent) {
                return nameIdentifier.textContent;
            }
        } catch (err: any) {
            // log to Panorama as an internal metric
            emitPanoramaCustomEvent({
                eventSource: 'panoramaInternal',
                eventType: funnelInteractionErrorType,
                eventContext: 'extractTextContent',
                eventDetail: err ? err.toString() : '',
            });
        }
    }
    return '';
};
